exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-advisory-team-tsx": () => import("./../../../src/pages/advisory-team.tsx" /* webpackChunkName: "component---src-pages-advisory-team-tsx" */),
  "component---src-pages-authenticate-tsx": () => import("./../../../src/pages/authenticate.tsx" /* webpackChunkName: "component---src-pages-authenticate-tsx" */),
  "component---src-pages-bookings-tsx": () => import("./../../../src/pages/bookings.tsx" /* webpackChunkName: "component---src-pages-bookings-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-corporate-social-responsibility-tsx": () => import("./../../../src/pages/corporate-social-responsibility.tsx" /* webpackChunkName: "component---src-pages-corporate-social-responsibility-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-explore-tsx": () => import("./../../../src/pages/explore.tsx" /* webpackChunkName: "component---src-pages-explore-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-group-sessions-tsx": () => import("./../../../src/pages/group-sessions.tsx" /* webpackChunkName: "component---src-pages-group-sessions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-members-messages-tsx": () => import("./../../../src/pages/members/messages.tsx" /* webpackChunkName: "component---src-pages-members-messages-tsx" */),
  "component---src-pages-members-user-tsx": () => import("./../../../src/pages/members/user.tsx" /* webpackChunkName: "component---src-pages-members-user-tsx" */),
  "component---src-pages-onboarding-tsx": () => import("./../../../src/pages/onboarding.tsx" /* webpackChunkName: "component---src-pages-onboarding-tsx" */),
  "component---src-pages-our-programs-tsx": () => import("./../../../src/pages/our-programs.tsx" /* webpackChunkName: "component---src-pages-our-programs-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-steam-academy-tsx": () => import("./../../../src/pages/steam-academy.tsx" /* webpackChunkName: "component---src-pages-steam-academy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-the-team-tsx": () => import("./../../../src/pages/the-team.tsx" /* webpackChunkName: "component---src-pages-the-team-tsx" */)
}

